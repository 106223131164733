import React from 'react';
import { useNavigate } from 'react-router';
import { courses } from "./courseData";

const Courses = () => {
    const navigate = useNavigate();

    const handleCourseClick = (course) => {
        navigate(`/courses/${course.name}`);
    };
    return (
        <div className="course-list">
            <h3>UPCOMING CLASSES</h3>
            <div className="course-grid">
                {courses.map(course => (
                    <div className="course-card" key={course.id} onClick={() => handleCourseClick(course)}>
                        <div className="course-image">
                            <img src={course.image} alt={course.name} />
                        </div>
                        <div className="course-details">
                            <h2>{course.name}</h2>
                            <p className="new-price">{course.price}</p>
                            <p className="new-price">{course.students}</p>
                            <button onClick={(e) => {
                                e.stopPropagation(); 
                                handleCourseClick(course);
                            }}>
                                Learn More
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Courses;
