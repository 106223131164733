import React from "react";
import { useNavigate } from 'react-router';
import { courses } from "./courseData";

const Services = () => {
  const navigate = useNavigate();

    const handleCourseClick = (course) => {
        navigate("/courses/"+course.name);
    };

  return (
    <div className="featured-main" id="courses">
      <div className="simple-flex">
        <div className="identifier"></div>
        <h4 className="bolder">FEATURED COURSES</h4>
      </div>
      <div className="simple-flex-courses">
      {courses.map(course => (
                    <div className="courses-box" key={course.id} onClick={() => handleCourseClick(course)}>
                       <h5 className="course-header bolder">{course.name}</h5>
      </div>
        ))}
      </div>
    </div>
  );
};

export default Services;
