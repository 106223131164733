import React from "react";
import Logo from "../assets/kayrex.jpg";
import { MdEmail } from "react-icons/md";
import { BsFacebook, BsInstagram,  BsPhoneFill,  BsTiktok, BsTwitter, BsWhatsapp } from "react-icons/bs";


const Footer = () => {

  // const RegisterLetter=async ()=>{
  //   await NewsLetter(email)
  //   emailUpdated("")
  //   alert("Thank you for signing up for Kayrex NewsLetter")
  //   document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  // }
  const RegisterLetter = () => {
    window.open("https://chat.whatsapp.com/B8UX1mBIZ3D5csGKUcOCdk",'_blank').focus();
};
  return (
    <div className="footermain">
      <div className="footertop">
        <div>
          <img alt="logo" src={Logo} className="footerlogo"/>
     
        </div>
        <div className="footerbootcamp">
            <p>Join our WhatsApp group, to enjoy free TECH Classes</p>
            <div className="d-flex">
              <button className="sendbut" onClick={()=>RegisterLetter()} ><BsWhatsapp></BsWhatsapp>&nbsp;&nbsp;Click to Join</button>
            </div>   
        </div>
        <div>
            <h3>Get In Touch</h3>
            <p><MdEmail></MdEmail> <a href='mailto:info@kayrexacademy.com' className="footer-phone">info@kayrexacademy.com</a></p>
            <p><BsPhoneFill></BsPhoneFill> <a className="footer-phone" href="tel:+353899435322">+353 89 943 5322</a>  </p>
            <div>
              <a
                href="https://www.facebook.com/profile.php?id=100094205407984
"
                target="_blank"
                rel="noreferrer"
              >
                <BsFacebook className="header-icons txt-white mx-2" />
              </a>

              <a
                href="https://www.instagram.com/kayrexacademy_/"
                target="_blank"
                rel="noreferrer"
              >
                <BsInstagram className="header-icons txt-white mx-2" />
              </a>
              <a
                href="https://www.tiktok.com/@kayrexacademy?_t=8oSvXbY6e1o&_r=1"
                target="_blank"
                rel="noreferrer"
              >
                <BsTiktok className="header-icons txt-white mx-2" />
              </a>
              
              <a
                href="https://x.com/kayrexacademy?s=11&t=6ie5kieNQlXKyB3DP1-wLg"
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitter className="header-icons txt-white mx-2" />
              </a>
            </div>
        </div>
      </div>
      <hr className="hrline"></hr>
      <p style={{textAlign:'center'}}>Copyright © 2024 Kayrex Academy LLC</p>
    </div>
  );
};

export default Footer;
