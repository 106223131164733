import React, {useEffect, useState} from "react";
import Header from "../components/header";
import Contactus from "../components/contactus";
import Footer from "../components/footer";
import CourseBanner from "../components/coursebanner";
import WhatYouLearn from "../components/whatyoulearn";
import CourseReview from "../components/coursereview";
import CourseDescription from "../components/coursedescription";
import Instructor from "../components/instructor";
import PracticalTools from "../components/practicaltools";
import CareerPath from "../components/careerpath";
import { useParams } from "react-router";
import { courses, emptyCourse } from "../components/courseData";


const CoursesPage = () => {
  const params = useParams();
  const [selectedCourse, setCourse]=useState(emptyCourse)
  useEffect(() => {
    const val=courses.find(course=>course.name.trim().toLowerCase()===params.course.trim().toLowerCase()|| course.anchor.trim().toLowerCase()===params.course.trim().toLowerCase())
    if(val)
      setCourse(val)
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header></Header>
      {selectedCourse ? (
        <CourseBanner selectedCourse={selectedCourse} />
      ) : (
        <p>Please select a course from the home page.</p>
      )}
      <CourseDescription  selectedCourse={selectedCourse}></CourseDescription>
      <WhatYouLearn  selectedCourse={selectedCourse}></WhatYouLearn>
      <PracticalTools selectedCourse={selectedCourse}></PracticalTools>
      <CareerPath selectedCourse={selectedCourse}></CareerPath>
      <Instructor  selectedCourse={selectedCourse}></Instructor>

      <CourseReview  selectedCourse={selectedCourse}></CourseReview>
     


      <Contactus></Contactus>
      <Footer></Footer>
    </div>
  );
};

export default CoursesPage;
